@import '../../App/variables.scss';

$app-commandbar-height: 40px;

.app-commandbar {
  height: $app-commandbar-height;
  width: 100%;
  padding: 0px calc(2% + 20px);

  background-color: $beijer-yellow;
  margin: 20px 0px;
  
  > button {
    padding: 0px 10px;
    margin: 0px 5px;
  }
  
}

.app-commandbar-right > div {
  margin: 4px 3px 0px 3px;
}
.app-commandbar-right .ant-picker {
  margin-top: 3px;;
}

.app-commandbar-button { 
  
  display: inline-block;
  line-height: $app-commandbar-height;
  height: $app-commandbar-height;
  padding: 0px 20px;

  > svg {
    font-size: 16px;
  }

  > span {
    margin-left: 10px;
    font-size: 14px;

    font-weight: 500;
  }
}
.app-commandbar-button.disabled {
  color: #676767;
  cursor: default;

}

.app-commandbar-button.enabled:hover {
  background: rgba(255,255,255,0.30);
  cursor: pointer;
}

.app-commandbar-right {
  float: right;
}