@import 'variables.scss';

* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
}


html,
body,
div#root,
div#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: $app-white;
}

h1 { font-size: 34px;}
h2 { font-size: 28px;}
h3 { font-size: 22px;}
h4 { font-size: 16px;}
h5 { font-size: 12px;}

.bold {
  font-weight: 600;
}

.center-text {
  text-align: center;
}
.app-page-content.center {
  text-align: center;
}

#app-language-selector {
  height: 20px;
  width: 200px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}


.app-padded-content {
  padding: 0px 20px;
  width: 96%;
  margin: 0px auto;
}

div#signing-in-wrapper {
  text-align: center;
  vertical-align: middle;
  height: 100%;
  padding-top: 25vh;

  #signing-in-logo {
    font-weight: 700;
    margin: 5vh 0;
    span {
      font-size: 40px;
    }
  }
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}