

.default-page-text {
  width: 230px;
  height: 150px;

  margin: auto;
  color: black;
  text-align: center;
  padding-top: 7%;

  h3 {
    font-size: 22px;
    letter-spacing: 1px;
  }
  h2 {
    font-size: 72px;
  }
}
