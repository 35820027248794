
.app-status-code-wrapper {
  margin-top:20%;
  color: black;

  display: flex;
  justify-content: space-evenly;

  .app-status-code {
    height: 350px;
    min-width: 400px;
    text-align: center;

    h1 {
      font-size: 200px;
      font-weight: 600;
      margin: 10px auto;
      line-height: 200px;
    }
  }
  .app-status-code-links {
    height: 350px;
    min-width: 400px;
    padding: 80px 30px;
    
    .app-status-code-link-icon {
      margin-right: 10px;
    }
  }
}

