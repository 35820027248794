



#app-orders {

  padding: 10px 20px;

  h3 {
    margin: 0;
  }

  .ant-breadcrumb {
    line-height: 40px;
  }

  
}

.app-orders-tab-nav-link {

  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  padding: 0px 10px 2px 10px;
  line-height: 30px;
  color: black;
}

.app-orders-tab-nav-link:hover {
  background-color: #F9F9F9;
  color: black;
}

.app-orders-tab-nav-link.active {
  padding-bottom: 0px;
  border-bottom: 1px solid black;
}
