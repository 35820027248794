@import '../../App/variables.scss';

#app-header {

  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: 90px 50px;
}

#app-header-bottom {
  
  grid-row: 2;
  grid-column: 1;
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 260px 1fr;
  grid-template-rows: 1fr;

  width: 96%;
  margin: 0px auto;
  padding: 0 30px;


  #app-header-nav {
    line-height: 50px;

    .app-main-navigation-link {
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
      display: inline-block;
      padding: 0px 10px 5px 10px;
      line-height: 45px;
      color: black;
    }
    
    a.app-main-navigation-link:hover {
      background-color: #F9F9F9;
    }
    
    a.app-link-active {
      font-weight: 600;
    }

    ul {
      margin: 0;
      li {
        font-size: 14px;
        display: inline-block;
        padding: 0px 10px;
      }
    }
  }
  


  #app-header-searchbar {
    grid-row: 1;
    grid-column: 2;

    margin-top: 10px;

    > .ant-input-search {
      margin: 0 !important;
    } 
  }

  #app-header-selector-wrapper {
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-template-rows: 1fr;
    grid-row: 1;
    grid-column: 3;
  }

  #app-header-selector {
    grid-row: 1;
    grid-column: 2;
    padding: 3px 0px;

    > span {
      font-size: 12px;
      font-style: italic;
    }
  }
}

#app-header-top {
  grid-row: 1;
  grid-column: 1;
  display: grid;

  grid-template-columns: 1fr 280px 1fr;
  grid-template-rows: 1fr;

  width: 96%;
  margin: 0px auto;
  padding: 20px 30px;
  border-bottom: 2px solid;

  #app-logo {
    height: 50px;
  }

  #app-header-logo {
    grid-row: 1;
    grid-column: 1;
  }

  #app-header-title {
    grid-row: 1;
    grid-column: 2;

    font-size: 22px;
    font-weight: 600;

    text-align: center;
    line-height: 50px;
  }

  #app-header-user {
    text-align: right;
    p { margin: 0; }
  }

}