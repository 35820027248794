

#app-order-info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  > div {
    flex-grow: 1;
    width: 25%;
    padding: 5px;
  }

}
.app-order-info-label-icon {
  float: right;
}
#app-order-header {
  display: grid;

  grid-template-columns: 1fr 250px 1fr;
  grid-template-rows: 1fr;

  text-align: center;

  h3 {
    grid-column: 2;
    margin: 0;
  }

  .ant-breadcrumb {
    line-height: 40px;
  }
}

#app-order-wrapper {
  display: grid;
  
  grid-template-columns: 1fr;

  //grid-template-columns: 1fr 3% 250px;
  grid-template-rows: 1fr;
}

#app-order-data {
  width: auto;
}

#app-order-events {
  grid-column: 3;
  min-width: 250px;
}
